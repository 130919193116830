import logo from '../../assets/images/logo.svg';
import { useParams } from 'react-router-dom';
import { useActivate } from './hooks';
import { useEffect, useState } from 'react';

const ActivateAccount = () => {
  const { token, accountId } = useParams();
  const [activated, setActivated] = useState(false);

  const { isError, isPending, activateAccount } = useActivate()

  useEffect(() => {
    const activate = async (token: string, accountId: string) => {
      await activateAccount({
        activationToken: token,
        customerId: accountId
      });

      setActivated(true);

      setTimeout(() => {
        const currentHost = window.location.hostname;
        const topDomain = currentHost.split('.').slice(-2).join('.'); // Extract the top-level domain
        window.location.href = `https://${topDomain}`;
      }, 5000);
    }

    if (!activated && token && accountId && !isPending && !isError) {
      activate(token, accountId)
    }
  }, [token, accountId, activateAccount, isPending, isError, activated]);

  return (
    <main className="p-16 flex justify-center items-center min-h-[100vh]">
      <div className="bg-lighter p-8 pb-16 pt-16 rounded-lg w-1/2 min-w-[400px] h-max">
        <img
          src={logo}
          alt="Mijnmortex logo"
          className="w-[150px] mb-16 flex items-center"
        />

        {
          isPending && (
            <>
              <h1 className="text-2xl mb-8">Account activeren</h1>

              <div className="bg-primary p-4 rounded-lg mb-4">
                We activeren je account. Zometeen wordt je doorverwezen naar het platform.
              </div>
            </>
          )
        }

        {
          isError && (
            <>
              <h1 className="text-2xl mb-8">Account activeren</h1>
              <div className="bg-error p-4 rounded-lg mb-4">
                Er is een fout opgetreden bij het activeren van je account. Probeer het later opnieuw.
              </div>
            </>
          )
        }

        {
          !isPending && !isError && (
            <>
              <h1 className="text-2xl mb-8">Account activeren</h1>
              <div className="bg-primary p-4 rounded-lg mb-4">
                Je account is geactiveerd. Je wordt nu doorverwezen naar het platform.
              </div>
            </>
          )
        }

      </div>
    </main>
  )
};

export default ActivateAccount;

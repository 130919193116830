import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useMutation } from '@tanstack/react-query';

import { doFetch } from '../utils';
import {
  ForgotPasswordDto,
  forgotPasswordDtoSchema,
} from './dto/forgotPasswordDto.dto';
import ApiError from '../utils/ApiError';

const URLS = {
  FORGOT_PASSWORD: '/api/v1/auth/forgot-password',
  VALIDATE_FORGOT_PASSWORD_TOKEN: (
    customerId: string,
    resetPasswordToken: string,
  ) =>
    `/api/v1/auth/validate-reset-password-token/${customerId}/${resetPasswordToken}`,
};

export const useClientForm = (signInDto: ForgotPasswordDto) => {
  return useForm<ForgotPasswordDto>({
    defaultValues: signInDto,
    resolver: zodResolver(forgotPasswordDtoSchema),
  });
};

export const useForgotPassword = () => {
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async (signInDto: ForgotPasswordDto) => {
      const { status, data } = await doFetch(
        URLS.FORGOT_PASSWORD,
        'POST',
        signInDto,
      );

      if (status !== 200) {
        throw new Error(data.message);
      }

      return data;
    },
  });

  const forgotPassword = async (signInDto: ForgotPasswordDto) => {
    return new Promise((resolve, reject) => {
      mutate(signInDto, {
        onSuccess: (data) => {
          resolve(data);
        },
        onError: reject,
      });
    });
  };

  return {
    isPending,
    isError,
    forgotPassword,
    error,
  };
};

export const useValidate = () => {
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async ({
      customerId,
      activationToken,
    }: {
      customerId: string;
      activationToken: string;
    }) => {
      const { status, data } = await doFetch(
        URLS.VALIDATE_FORGOT_PASSWORD_TOKEN(customerId, activationToken),
        'POST',
        {},
      );

      if (status !== 200) {
        throw new ApiError(data.message, status);
      }

      return data;
    },
  });

  const validateToken = async (activateData: {
    customerId: string;
    activationToken: string;
  }) => {
    return new Promise((resolve, reject) => {
      mutate(activateData, {
        onSuccess: (data) => {
          resolve(data);
        },
        onError: reject,
      });
    });
  };

  return {
    isPending,
    isError,
    validateToken,
    error,
  };
};

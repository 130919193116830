import { Suspense } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { Loading } from './partials';

import ForgotPasswordPage from './forgot-password/Page';
import LoginPage from './login/Page';
import RegisterPage from './register/Page';
import ResetPasswordPage from './reset-password/Page';
import ActivateAccountPage from './activate-account/Page';

const Router = () => {
  return (
    <div className="flex gap-4">
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password/:accountId/:token" element={<ResetPasswordPage />} />
            <Route
              path="/activate-account/:accountId/:token"
              element={<ActivateAccountPage />}
            />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="*" element={<LoginPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
};

export default Router;

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useMutation } from '@tanstack/react-query';
import { doFetch } from '../utils';
import { SignInDto, signInDtoSchema } from './dto/loginDto.dto';
import ApiError from '../utils/ApiError';

const URLS = {
  LOGIN: '/api/v1/auth/login',
};

export const useClientForm = (signInDto: SignInDto) => {
  return useForm<SignInDto>({
    defaultValues: signInDto,
    resolver: zodResolver(signInDtoSchema),
  });
};

export const useLogin = () => {
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async (signInDto: SignInDto) => {
      const { status, data } = await doFetch(URLS.LOGIN, 'POST', signInDto);

      if (status !== 200) {
        throw new ApiError(data.message, status);
      }

      return data;
    },
  });

  const login = async (signInDto: SignInDto) => {
    return new Promise((resolve, reject) => {
      mutate(signInDto, {
        onSuccess: (data) => {
          resolve(data);
        },
        onError: (error) => {
          // Ensure the error is an instance of ApiError
          if (error instanceof ApiError) {
            reject(error);
          } else {
            // Create a generic ApiError if it's not
            reject(new ApiError(error.message || 'Unknown error', 500));
          }
        },
      });
    });
  };

  return {
    isPending,
    isError,
    login,
    error,
  };
};

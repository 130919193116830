import { MoveRight } from 'lucide-react';
import { useClientForm, useLogin } from './hooks';
import ApiError from '../utils/ApiError';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import InputError from '../elements/InputError';
import { getWebsiteUrl } from '../utils';

const LoginForm = () => {
  const { login } = useLogin();
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();

  const redirectUrl = searchParams.get('redirect_url');


  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useClientForm({
    email: '',
    password: '',
  });

  const doLogin = async () => {
    try {
      await login(getValues());
      setError('');
      reset();

      if (redirectUrl) {
        window.location.href = redirectUrl;
        return
      }

      window.location.href = getWebsiteUrl();
    } catch (error) {
      if (error instanceof ApiError) {
        if ((error.status === 401 || error.status === 404)) {
          setError('Je e-mail of wachtwoord is onjuist.');
        }

        if (error.status === 409) {
          setError('Je account is nog niet geactiveerd. Er is een e-mail naar je verstuurd met een link om je account te activeren.');
        }
      } else {
        setError(
          'Er is een onverwachte fout opgetreden. probeer het later opnieuw.',
        );
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(doLogin)}>
      <div>
        {error && (
          <div className="bg-error-bg text-error p-4 rounded-lg mb-4">
            <p>{error}</p>
          </div>
        )}
      </div>
      <div className="mb-4 w-full rounded-lg">
        <label htmlFor="email" className="block">
          Vul je e-mail in.
        </label>
        <input
          {...register('email')}
          type="text"
          placeholder="klant@diy-atelier.be"
          className="w-full p-4 mt-4 rounded-lg bg-primary"
        />

        {errors.email && <InputError text={errors.email.message} />}
      </div>
      <div className="mb-4 w-full rounded-lg">
        <label htmlFor="password" className="block">
          Vul je wachtwoord in.
        </label>
        <input
          {...register('password')}
          type="password"
          placeholder="**********"
          className="w-full p-4 mt-4 rounded-lg bg-primary"
        />

        {errors.password && <InputError text={errors.password.message} />}
      </div>
      <button
        type="submit"
        className="bg-accent w-full rounded-lg p-4 text-primary mb-4 flex gap-8 justify-center"
      >
        Aanmelden
        <MoveRight />
      </button>
    </form>
  );
};

export default LoginForm;

import { z } from 'zod';

export const registerDtoSchema = z.object({
  name: z.string().min(1, { message: 'Naam is verplicht.' }),
  email: z.string().email({ message: 'Vul een geldig e-mailadres in.' }),
  password: z
    .string()
    .min(6, { message: 'Het wachtwoord moet minimaal 6 tekens lang zijn.' }),
});

export type RegisterDto = z.infer<typeof registerDtoSchema>;

import { useMutation } from '@tanstack/react-query';

import { doFetch } from '../utils';
import ApiError from '../utils/ApiError';

const URLS = {
  ACTIVATE_ACCOUNT: (customerId: string, activationToken: string) =>
    `/api/v1/auth/activate-account/${customerId}/${activationToken}`,
};

export const useActivate = () => {
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async ({
      customerId,
      activationToken,
    }: {
      customerId: string;
      activationToken: string;
    }) => {
      const { status, data } = await doFetch(
        URLS.ACTIVATE_ACCOUNT(customerId, activationToken),
        'POST',
        {},
      );

      if (status !== 200) {
        throw new ApiError(data.message, status);
      }

      return data;
    },
  });

  const activateAccount = async (activateData: {
    customerId: string;
    activationToken: string;
  }) => {
    return new Promise((resolve, reject) => {
      mutate(activateData, {
        onSuccess: (data) => {
          resolve(data);
        },
        onError: reject,
      });
    });
  };

  return {
    isPending,
    isError,
    activateAccount,
    error,
  };
};

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useMutation } from '@tanstack/react-query';

import { doFetch } from '../utils';
import { RegisterDto, registerDtoSchema } from './dto/registerDto.dto';
import ApiError from '../utils/ApiError';

const URLS = {
  REGISTER_ACCOUNT: '/api/v1/auth/register',
};

export const useRegisterForm = (signInDto: RegisterDto) => {
  return useForm<RegisterDto>({
    defaultValues: signInDto,
    resolver: zodResolver(registerDtoSchema),
  });
};

export const useRegister = () => {
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async (signUpDto: RegisterDto) => {
      const { status, data } = await doFetch(
        URLS.REGISTER_ACCOUNT,
        'POST',
        signUpDto,
      );

      if (status !== 201) {
        throw new ApiError(data.message, status);
      }

      return data;
    },
  });

  const register = async (signInDto: RegisterDto) => {
    return new Promise((resolve, reject) => {
      mutate(signInDto, {
        onSuccess: (data) => {
          resolve(data);
        },
        onError: reject,
      });
    });
  };

  return {
    isPending,
    isError,
    registerAccount: register,
    error,
  };
};

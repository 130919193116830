import { useNavigate } from 'react-router-dom';
import LoginForm from './_loginForm';
import logo from '../../assets/images/logo.svg';
import { MoveLeft } from 'lucide-react';
import { getWebsiteUrl } from '../utils';

const LoginPage = () => {
  const navigate = useNavigate();

  const redirectToRegister = () => {
    navigate('/register');
  };

  const redirectToForgotPassword = () => {
    navigate('/forgot-password');
  };

  const redirectToWebsite = () => {
    window.location.href = getWebsiteUrl();
  }

  return (
    <main className="p-4 flex flex-center items-center min-h-[100vh] md:p-8">
      <div className="relative bg-lighter p-8 pb-16 pt-8 rounded-lg w-full h-[calc(100vh - 2rem)] sm:h-[inherit] max-w-[600px] mx-auto">
        <button onClick={redirectToWebsite} className="mb-8 text-sm flex justify-start items-center gap-4 hover:text-accent-hover">
          <MoveLeft className="w-[20px] h-[20px]" />
          Ga terug naar de website
        </button>
        <img
          src={logo}
          alt="DIY atelier logo"
          className="w-[100px] mb-16 flex items-center"
        />

        <h1 className="text-4xl mb-16">Welkom tot het platform!</h1>

        <LoginForm />

        <button
          onClick={redirectToForgotPassword}
          className="flex mb-4 mt-8 m-auto"
        >
          Wachtwoord vergeten
        </button>
        <div className="h-0.5 bg-accent w-1/2 mb-4 m-auto"></div>

        <button onClick={redirectToRegister} className="flex mb-4 m-auto">
          Nog geen account?{' '}
          <strong className="ml-2 text-accent font-normal">
            Registreer hier
          </strong>
        </button>


      </div>
    </main>
  );
};

export default LoginPage;

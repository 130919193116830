import { useNavigate } from 'react-router-dom';
import ForgotPasswordForm from './_forgotPasswordForm';
import logo from '../../assets/images/logo.svg';
import { MoveLeft } from 'lucide-react';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  const redirectToLogin = () => {
    navigate('/login');
  };

  return (
    <main className="p-4 flex flex-center items-center min-h-[100vh] md:p-8">
      <div className="bg-lighter p-8 pb-16 pt-8 rounded-lg w-full h-[calc(100vh - 2rem)] sm:h-[inherit] max-w-[600px] mx-auto">
        <img
          src={logo}
          alt="DIY atelier logo"
          className="w-[100px] mb-16 flex items-center"
          onClick={redirectToLogin}
        />

        <button onClick={redirectToLogin} className="flex mb-8 gap-4">
          <MoveLeft />
          Ga naar login
        </button>

        <h1 className="text-4xl mb-16">Wachtwoord vergeten</h1>

        <ForgotPasswordForm />
      </div>
    </main>
  );
};

export default ForgotPasswordPage;

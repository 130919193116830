import { MoveRight } from 'lucide-react';
import { useState } from 'react';
import { useClientForm, useForgotPassword } from './hooks';
import InputError from '../elements/InputError';

const ForgotPasswordForm = () => {
  const { forgotPassword } = useForgotPassword();
  const [sent, setSent] = useState(false);
  const [sentTo, setSentTo] = useState('');
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useClientForm({
    email: '',
  });

  const doForgotPassword = async () => {
    try {
      const values = getValues();
      await forgotPassword(values);
      setSent(true);
      setSentTo(values.email);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(doForgotPassword)}>
      {sent && (
        <div className="bg-primary p-4 rounded-lg mb-4">
          <p>
            We hebben een e-mail verstuurd naar
            <strong>{` ${sentTo} `}</strong> met instructies om je wachtwoord te
            resetten.
          </p>
        </div>
      )}

      {!sent && (
        <>
          <div className="mb-4 w-full rounded-lg">
            <label htmlFor="email" className="block">
              Vul je e-mail in.
            </label>
            <input
              {...register('email')}
              type="text"
              placeholder="klant@diy-atelier.be"
              className="w-full p-4 mt-4 rounded-lg bg-primary"
            />

            {errors.email && <InputError text={errors.email.message} />}
          </div>
          <button
            type="submit"
            className="bg-accent w-full rounded-lg p-4 text-primary mb-4 flex gap-8 justify-center"
          >
            Verstuur e-mail
            <MoveRight />
          </button>
        </>
      )}
    </form>
  );
};

export default ForgotPasswordForm;

export const doFetch = async (
  url: string,
  method: string,
  data?: any,
): Promise<{ status: number; data: any }> => {
  const response = await fetch(url, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
    },
    body: method !== 'GET' ? JSON.stringify(data) : undefined, // body data type must match "Content-Type" header
  });

  const parseData = await response.json();

  return {
    status: response.status,
    data: parseData,
  };
};

export const getWebsiteUrl = () => {
  const currentHost = window.location.hostname;

  const subdomain = currentHost
    .split('.')
    .slice(0, -2)
    .filter((part) => part !== 'sso')
    .join('.');

  const topDomain = currentHost.split('.').slice(-2).join('.');
  return `https://${subdomain ? `${subdomain}.` : ''}${topDomain}`;
};

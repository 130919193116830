import { useNavigate, useParams } from 'react-router-dom';
import ResetPasswordForm from './_resetPasswordForm';
import logo from '../../assets/images/logo.svg';
import { MoveLeft } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useValidate } from '../forgot-password/hooks';

const ResetPasswordPage = () => {
  const { token, accountId } = useParams();
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);

  const { isError, isPending, validateToken } = useValidate()

  useEffect(() => {
    if (token && accountId && !isPending && !isError) {
      const validate = async () => {
        await validateToken({
          activationToken: token,
          customerId: accountId
        });
        setValidated(true);
      };

      validate();
    }
  }, []);

  const redirectToLogin = () => {
    navigate('/login');
  };

  return (
    <main className="p-16 flex flex-center items-center min-h-[100vh]">
      <div className="bg-lighter p-8 pb-16 pt-8 rounded-lg w-full h-[calc(100vh - 2rem)] sm:h-[inherit] max-w-[600px] mx-auto">
        <img
          src={logo}
          alt="DIY atelier logo"
          className="w-[100px] mb-16 flex items-center"
          onClick={redirectToLogin}
        />

        <button onClick={redirectToLogin} className="flex mb-8 gap-4">
          <MoveLeft />
          Ga terug
        </button>

        <h1 className="text-4xl mb-16">Stel nieuw wachtwoord in</h1>

        {
          validated && (
            <ResetPasswordForm />
          )
        }

        {
          !validated && (
            <div className="bg-primary p-4 rounded-lg mb-4">
              Deze link is niet langer geldig. Start de procedure opnieuw.
            </div>)
        }
      </div>
    </main>
  );
};

export default ResetPasswordPage;

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useMutation } from '@tanstack/react-query';

import { doFetch } from '../utils';
import {
  ResetPasswordDto,
  resetPasswordDtoSchema,
} from './dto/resetPasswordDto.dto';

const URLS = {
  RESET_PASSWORD: (
    customerId: string | undefined,
    resetToken: string | undefined,
  ) => `/api/v1/auth/reset-password/${customerId}/${resetToken}`,
};

export const useResetForm = (signInDto: ResetPasswordDto) => {
  return useForm<ResetPasswordDto>({
    defaultValues: signInDto,
    resolver: zodResolver(resetPasswordDtoSchema),
  });
};

export const useResetPassword = () => {
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async ({
      signInDto,
      token,
      accountId,
    }: {
      signInDto: ResetPasswordDto;
      token: string | undefined;
      accountId: string | undefined;
    }) => {
      const { status, data } = await doFetch(
        URLS.RESET_PASSWORD(accountId, token),
        'POST',
        signInDto,
      );

      if (status !== 200) {
        throw new Error(data.message);
      }

      return data;
    },
  });

  const resetPassword = async (
    signInDto: ResetPasswordDto,
    token: string | undefined,
    accountId: string | undefined,
  ) => {
    return new Promise((resolve, reject) => {
      mutate(
        {
          signInDto,
          token,
          accountId,
        },
        {
          onSuccess: (data) => {
            resolve(data);
          },
          onError: reject,
        },
      );
    });
  };

  return {
    isPending,
    isError,
    resetPassword,
    error,
  };
};

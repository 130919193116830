import { MoveRight } from 'lucide-react';
import { useState } from 'react';
import { useRegisterForm, useRegister } from './hooks';
import InputError from '../elements/InputError';

const RegisterAccountForm = () => {
  const [registered, setRegistered] = useState(false);
  const [registeredEmail, setRegisteredEmail] = useState('');
  const [error, setError] = useState<undefined | string>();

  const { registerAccount } = useRegister();
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useRegisterForm({
    name: '',
    email: '',
    password: '',
  });

  const doRegister = async () => {
    try {
      await registerAccount(getValues());
      setRegisteredEmail(getValues().email);
      setRegistered(true);
      reset();
    } catch (error) {
      setError(
        'Er is een onverwachte fout opgetreden. probeer het later opnieuw.',
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(doRegister)}>
      {
        registered && error && (
          <div className="bg-error-bg p-4 rounded-lg mb-4">
            <p>{error}</p>
          </div>
        )
      }
      {!error && registered && (
        <div className="bg-primary p-4 rounded-lg mb-4">
          We hebben een e-mail verstuurd naar{' '}
          <strong>{` ${registeredEmail} `}</strong> met instructies om je
          account te activeren.
        </div>
      )}
      {!registered && (
        <>
          {error && (
            <div className="bg-error-bg p-4 rounded-lg mb-4">
              <p>{error}</p>
            </div>
          )}

          <div className="mb-4 w-full rounded-lg">
            <label htmlFor="name" className="block">
              Vul je naam in.
            </label>
            <input
              {...register('name')}
              type="text"
              placeholder="Jan Janssens"
              className="w-full p-4 mt-4 rounded-lg bg-primary"
            />

            {errors.name && <InputError text={errors.name.message} />}
          </div>
          <div className="mb-4 w-full rounded-lg">
            <label htmlFor="email" className="block">
              Vul je e-mail in.
            </label>
            <input
              {...register('email')}
              type="text"
              placeholder="klant@diy-atelier.be"
              className="w-full p-4 mt-4 rounded-lg bg-primary"
            />

            {errors.email && <InputError text={errors.email.message} />}
          </div>

          <div className="mb-4 w-full rounded-lg">
            <label htmlFor="email" className="block">
              Kies een wachtwoord.
            </label>
            <input
              {...register('password')}
              type="password"
              placeholder="Minum 8 karakters lang."
              className="w-full p-4 mt-4 rounded-lg bg-primary"
            />

            {errors.password && <InputError text={errors.password.message} />}
          </div>
          <button
            type="submit"
            className="bg-accent w-full rounded-lg p-4 text-primary mb-4 flex gap-8 justify-center"
          >
            Registreer je account
            <MoveRight />
          </button>
        </>
      )}
    </form>
  );
};

export default RegisterAccountForm;

import { z } from 'zod';

export const signInDtoSchema = z.object({
  email: z.string().email({ message: 'Vul een geldig e-mailadres in.' }),
  password: z
    .string()
    .min(6, { message: 'Vul je wachtwoord van je DIY-atelier account in' }),
});

export type SignInDto = z.infer<typeof signInDtoSchema>;
